import React from 'react';
import './maintenance.css';

const Maintanance = () => {
    return (
        <div className="maintenance-body">
            {/* Logo placed outside the container */}
            <img 
                src="earnersWaveLogo.png" 
                alt="Logo" 
                className="maintenance-logo" 
            />
            <div className="maintenance-container">
                <h1 className="maintenance-title">Under Maintenance</h1>
                <div className="maintenance-animation"></div>
                <p className="maintenance-text">
                    We are currently working on something amazing. <br /> Thanks for your patience!
                </p>
                <div className="maintenance-footer">&copy;Earners Wave</div>
            </div>
        </div>
    );
};

export default Maintanance;
