// Approute.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Maintanance from "../Compnents/Maintanance";


function Approute() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Maintanance />} />
      </Routes>
    </Router>
  );
}

export default Approute;
